.container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.itemsContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.itemsContainer > div {
  height: 100%;
}

.itemContainer {
  position: relative;
  height: 100%;
}

.details {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1;
  left: 100%;
  padding: calc(var(--spacingUnit) * 3) var(--spacingUnit);
  opacity: 0;
  transform: translateX(-50%);
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  line-height: 1.5;
}

[aria-hidden="true"] .details {
  display: none;
}

@media screen and (min-width: 640px) {
  .details {
    width: calc(var(--spacingUnit) * 15);
  }
}

.detailsVisible {
  transform: translateX(-100%);
  opacity: 1;
}

.logo {
  color: #d8d8d8;
}

.title {
  font-weight: normal;
}

.imageContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.image {
  position: absolute;
  left: 50%;
  top: 50%;
  max-width: 100%;
  max-height: 100%;
  transform: translate(-50%, -50%);
}

@supports (object-fit: contain) {
  .image {
    transform: none;
    left: 0;
    top: 0;
    position: static;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

.control {
  position: absolute;
  padding: var(--spacingUnit);
  color: #9b9b9b;
  z-index: 2;
}

.control:hover,
.control:focus {
  color: #000;
  outline: 0;
}

.control svg {
  height: var(--spacingUnit);
  display: block;
  fill: currentColor;
}

.exit {
  left: 0;
  top: 0;
}

.info {
  right: 0;
  top: 0;
  border: 0;
  background: none;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: inherit;
}

.next {
  right: 0;
  bottom: 0;
}

.prev {
  left: 0;
  bottom: 0;
}

@media screen and (min-width: 1024px) {
  .control svg {
    height: 40px;
  }

  .info {
    font-size: 40px;
  }
}
