:root {
  --spacingUnit: calc(100vw / 15);
}

@media screen and (min-width: 415px) {
  :root {
    --spacingUnit: 24px;
  }
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

img {
  max-width: 100%;
  display: block;
}

a {
  color: #000;
  text-decoration: underline;
}

button {
  border: 0;
  background: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}

html,
body,
body > div {
  height: 100%;
}

.container {
  padding: var(--spacingUnit);
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
  min-height: 100%;
}

@media screen and (min-width: 1024px) {
  .container {
    padding: calc(var(--spacingUnit) * 2);
  }
}
