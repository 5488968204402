.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.imageContainer {
  margin-bottom: var(--spacingUnit);
}

.textContainer {
  line-height: 1.4;
}

.textContainer p:not(:last-child) {
  margin-bottom: 1em;
}

@media screen and (min-width: 640px) {
  .content {
    display: flex;
  }

  .imageContainer {
    width: 312px;
    flex-shrink: 0;
    margin-right: var(--spacingUnit);
  }

  .textContainer {
    flex-grow: 1;
    max-width: 35em;
  }
}

@media screen and (min-width: 1024px) {
  .textContainer {
    font-size: 24px;
  }

  .imageContainer {
    margin-right: calc(var(--spacingUnit) * 2);
    margin-top: var(--spacingUnit);
  }

  .textContainer {
    margin-top: var(--spacingUnit);
  }
}
