.container {
  text-transform: uppercase;
  line-height: 1;
  display: block;
}

.item {
  display: block;
  color: #9b9b9b;
  text-decoration: none;
  letter-spacing: 0.1em;
  margin: var(--spacingUnit) 0;
  line-height: var(--spacingUnit);
}

.item:hover,
.item.is-selected {
  color: #000;
}

@media screen and (min-width: 280px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .item {
    margin: 0;
    padding: var(--spacingUnit) 0;
    font-size: 4.6vw;
  }

  .logoHolder {
    width: 100%;
  }
}

@media screen and (min-width: 415px) {
  .logoHolder {
    margin-right: 2vw;
  }

  .logoHolder svg {
    max-width: 312px;
  }

  .item {
    padding: 24px 0;
  }
}

@media screen and (min-width: 640px) {
  .container {
    align-items: flex-end;
    margin-bottom: 24px;
  }

  .logoHolder {
    width: 312px;
  }

  .item {
    padding: 0;
    line-height: 0.7;
    font-size: 2.4vw;
  }
}

@media screen and (min-width: 1600px) {
  .item {
    font-size: 38.4px;
  }
}
