.itemList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: calc(var(--spacingUnit) * -1);
}

.item {
  --itemsPerRow: 4;
  width: calc(
    (100% - var(--spacingUnit) * (var(--itemsPerRow))) / var(--itemsPerRow)
  );
  margin-bottom: var(--spacingUnit);
  margin-right: var(--spacingUnit);
}

.itemLink {
  display: block;
  padding-bottom: 100%;
  display: block;
  position: relative;
}

.itemImg {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  max-height: 100%;
  mix-blend-mode: multiply;
}

.itemText {
  position: absolute;
  bottom: -1em;
  text-align: center;
  width: 100%;
  left: 0;
  font-size: 12px;
}

@media screen and (min-width: 688px) {
  .item {
    --itemsPerRow: 5;
  }
}
