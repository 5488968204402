.container {
  display: flex;
  flex-direction: column;
}

.imgHolder {
  position: relative;
  flex-grow: 1;
  margin: 0 calc(-1 * var(--spacingUnit));
}

@media screen and (min-width: 1024px) {
  .imgHolder {
    margin: 48px calc(-2 * var(--spacingUnit)) 24px;
  }
}

.img {
  max-height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tagline {
  text-transform: uppercase;
  text-align: center;
  position: relative;
  top: 0.1em;
  letter-spacing: 0.1em;
  line-height: 1.1;
  margin-top: var(--spacingUnit);
}

@media screen and (min-width: 280px) {
  .tagline {
    font-size: 4.6vw;
  }
}

@media screen and (min-width: 640px) {
  .tagline {
    font-size: 2.4vw;
  }

  .tagline br {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .tagline {
    margin-top: calc(2 * var(--spacingUnit));
  }
}

@media screen and (min-width: 1600px) {
  .tagline {
    font-size: 38.4px;
  }
}

.nico {
  /*font-weight: 500;*/
}
